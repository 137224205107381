import React from 'react';

function Footer() {
    return (
        <div name="socials" id="contact">

            <h3>🍦Contact us🍦</h3>
           
            <p>Email Address: icecreamshops@gmail.com | #: 666-666-6666</p>

     

        </div>
    )
}

export default Footer